import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-message-dialog',
  templateUrl: './generic-message-dialog.component.html',
  styleUrls: ['./generic-message-dialog.component.scss']
})
export class GenericMessageDialogComponent implements OnInit {

  message: string;

  constructor(private dialogRef: MatDialogRef<GenericMessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.message = this.data.message;
  }

  ok() {
    this.dialogRef.close(true);
  }

}
