import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixedViewDatepickerHeaderComponent } from './fixed-view-datepicker-header/fixed-view-datepicker-header.component';
import { MaterialModule } from '../material/material.module';
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { GenericMessageDialogComponent } from './generic-message-dialog/generic-message-dialog.component';
import { ExcelDatePipe } from './pipes/excel-date.pipe';
import { HeaderComponent } from '../header/header.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ExcelDatePipe,
    FixedViewDatepickerHeaderComponent,
    GenericDialogComponent,
    GenericMessageDialogComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  exports: [
    ExcelDatePipe,
    HeaderComponent
  ],
  entryComponents: [
    FixedViewDatepickerHeaderComponent,
    GenericDialogComponent,
    GenericMessageDialogComponent
  ]
})
export class SharedModule { }
