export const environment = {
  name: 'prd',
  production: true,
  sandbox: false,
  apiPath: 'https://spd.ey.com/api/',
  mock: false,
  msal: {
    clientId: 'b6126309-4e6d-4aab-8603-5af70587e5b7',
    redirectUri: 'https://spd.ey.com',
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    consentScopes: [
      'user.read',
      'openid',
      'profile',
    ],
    protectedResourceKey: 'https://spd.ey.com/api/*',
    protectedResourceValues: ['api://b6126309-4e6d-4aab-8603-5af70587e5b7/access_as_user']
  }
};
