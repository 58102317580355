import { Component, OnInit } from '@angular/core';
import { LogoutDatastoreService } from './shared/datastores/logout-datastore.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spd-client';
  isLogoutPage: Observable<boolean>;

  constructor(private logoutDatastoreService: LogoutDatastoreService) { }

  ngOnInit(): void {
    this.isLogoutPage = this.logoutDatastoreService.isLogoutPage();
  }

}
