import { BrowserModule } from '@angular/platform-browser';
import { Inject, NgModule, Optional } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { DOCUMENT, PercentPipe } from '@angular/common';

export const msal = environment.msal;
export const msalModule = msal
  ? MsalModule.forRoot({
    auth: {
      clientId: msal.clientId,
      redirectUri: msal.redirectUri,
      authority: msal.authority,
      postLogoutRedirectUri: msal.redirectUri + '/logout'
    },
    cache: {
      cacheLocation: 'sessionStorage',
    },
  }, {
    popUp: false,
    consentScopes: msal.consentScopes,
    protectedResourceMap: [
      [msal.protectedResourceKey, msal.protectedResourceValues],
    ]
  })
  : [];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    msalModule
  ],
  providers: [
	  environment.msal ? { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true } : [],
    PercentPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
	constructor(@Optional() module: MsalModule, @Inject(DOCUMENT) private _document: HTMLDocument) {
    this._document.getElementById('appFavicon').setAttribute('href', environment.sandbox ? 'favicon.ico' : '/assets/img/favicon_ey.ico');
  }
}
