import { NgModule, Optional } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { environment } from '../environments/environment';

const authGuard: any[] = environment.msal ? [MsalGuard] : [];

const routes: Routes = [{
    path: '',
    redirectTo: 'workspace',
    pathMatch: 'full'
  },
  {
    path: 'workspace',
    loadChildren: './workspace/workspace.module#WorkspaceModule',
    canActivate: [...authGuard]
  }, {
    path: 'product-developer',
    loadChildren: './product-developer/product-developer.module#ProductDeveloperModule',
    canActivate: [...authGuard]
  }, {
    path: 'product-developer/:id',
    loadChildren: './product-developer/product-developer.module#ProductDeveloperModule',
    canActivate: [...authGuard]
  }, {
    path: 'spd-configuration',
    loadChildren: './spd-configuration/spd-configuration.module#SpdConfigurationModule',
    canActivate: [...authGuard]
  }, {
    path: 'logout',
    loadChildren: './logout/logout.module#LogoutModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(@Optional() msalService: MsalService) {
	  if (msalService) {
		  msalService.handleRedirectCallback(_ => { });
	  }
  }
}
