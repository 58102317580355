import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  displayLogo: boolean = !environment.sandbox;
  displayLogout: boolean = !!environment.msal;

  constructor(private router: Router) { }

  ngOnInit() { }

  logout() {
    this.router.navigateByUrl('logout');
  }
}
