import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'excelDate' })
export class ExcelDatePipe implements PipeTransform {

  transform(excelDate: number): any {
    // JavaScript dates can be constructed by passing milliseconds
    // since the Unix epoch (January 1, 1970) example: new Date(12312512312);

    // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970,
	// 2. plus 1 (Google "excel leap year bug")
	// 3. plus 1 (Excel shows dates without hours as 00:00 of next day, but Angular treat 00:00 as day before - possibly its also Locale dependent)
    // 4. Convert to milliseconds.

    return new Date((excelDate - (25567 + 2))*86400*1000);
  }

}
