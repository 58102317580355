import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoutDatastoreService {

  private _isLogoutPage = new BehaviorSubject<boolean>(false);

  constructor() { }

  public isLogoutPage(): Observable<boolean> {
    return this._isLogoutPage;
  }

  public next(value: boolean): void {
    return this._isLogoutPage.next(value);
  }
}
